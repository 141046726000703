import { useLayoutEffect, useRef } from 'react';

export default function usePopoverInsideScreen<T extends HTMLElement = HTMLElement>(padding = '0px', paddingRight = padding) {
    const popoverRef = useRef<T>(null);

    useLayoutEffect(() => {
        const node = popoverRef.current;

        if (!node) return;

        const { left, right } = node.getBoundingClientRect();
        const overflowWidth = right - document.body.clientWidth;

        if (overflowWidth > 0) {
            node.style.setProperty('--popover-translation-x', `calc(-${overflowWidth}px - ${paddingRight})`);
            node.style.translate = 'var(--popover-translation-x) 0';
        } else if (left < 0) {
            node.style.setProperty('--popover-translation-x', `calc(${-left}px + ${padding})`);
            node.style.translate = 'var(--popover-translation-x) 0';
        }
    }, [popoverRef.current, padding, paddingRight]);

    return popoverRef;
}
