import { createElement } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import skyra from './analytics/skyra';
import { getComponent } from './clientComponents';
import GlobalContextProvider from './generic/GlobalContextProvider';
import detectSanityPresentationMode, { lazyLoadVisualEditing } from './sanity/infrastructure/detectSanityPresentationMode';
import polyfillTargetInsideDetailsElement from './utils/polyfillTargetInsideDetailsElement';

/**
 * This is run on page load. It will look for html elements with data-react-component="Something" and
 * apply that react component to the element. It will use data-react-props="{}" and any data-react-prop-xxxxxx="something" as a
 * prop for the react component.
 *
 * It will try to rehydrate the element, assuming that it has been prerendered on the server.
 */
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll<HTMLElement>('[data-react-component]').forEach(async (elm) => {
        // Ignore this island if it is inside another island (react has already been applied to this subtree)
        if (elm.parentElement?.closest('[data-react-component]')) return;

        const componentName = elm.dataset['reactComponent'];
        if (!componentName) throw new Error('Missing value in data-react-component');

        // Get the react component by name
        const component = getComponent(componentName);

        // Build the props object from data-react-props="{}"
        const props = JSON.parse(elm.dataset['reactProps'] ?? '{}');

        // Wrap the component with the GlobalContextProvider, which provides all the useful contexts
        // that should be available everywhere.
        const element = createElement(GlobalContextProvider, {}, createElement(component, props));

        // Rehydrate the element, unless it is empty
        if (elm.childElementCount === 0) {
            createRoot(elm).render(element);
        } else {
            hydrateRoot(elm, element);
        }
    });

    polyfillTargetInsideDetailsElement();

    skyra();
    detectSanityPresentationMode(lazyLoadVisualEditing);
});
