export function cartURL() {
    return '/cart';
}

export function checkoutURL() {
    return '/cart/checkout';
}

export function searchURL(query?: string) {
    return query === undefined ? '/search' : `/search?q=${encodeURIComponent(query)}`;
}

export function searchStockURL(storeId: string) {
    return `/search?q=:relevance:availableInStores:${storeId}`;
}

export function termsAndConditionsURL() {
    return '/salgsbetingelser';
}

export function beforeTerminalSpinnerPageURL() {
    return `${cartURL()}/beforeTerminal`;
}

export function orderConfirmationURL(orderCode: string) {
    return `/checkout/orderConfirmation/${orderCode}`;
}

export function wishlistURL() {
    return '/wishlist';
}

export function wishlistExportURL(code: string, type: string) {
    return `/wishlist/${code}/${type}`;
}

export function wishlistExportCsvURL(code: string) {
    return wishlistExportURL(code, 'csv');
}

export function wishlistExportPdfURL(code: string) {
    return wishlistExportURL(code, 'pdf');
}

export function accountURL() {
    return '/my-account';
}

export function ordersURL(params = '') {
    return `${accountURL()}/orders${params}`;
}

export function orderURL(code: string, params?: string) {
    const orderParams = params ? `?${params}` : '';
    return `${accountURL()}/order/${code}${orderParams}`;
}

export function listOfListsURL() {
    return '/wishlist';
}

export function faqURL() {
    return '/faq';
}

export function faqCategoryURL(categoryId: string) {
    return `${faqURL()}/categories/${categoryId}`;
}

export function emailVerificationURL() {
    return '/emailverification/verificationrequired';
}

export function consentVerificationURL() {
    return '/my-account/confirmation';
}

export function storeURL(storeId: string) {
    return `/butikk/${storeId}`;
}

export function loginURL() {
    return '/login';
}

export function loginURLWithRedirect() {
    return `/login?redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`;
}

export function findStoreUrl() {
    return '/finn-butikk';
}

export function storeCategoriesUrl() {
    return '/butikk-kategoriene';
}

export function sustainabilitySymbolsUrl() {
    return '/barekraftsymbolene';
}

export function giftCardBalanceCheckUrl() {
    return '/giftcard-balance-check';
}
