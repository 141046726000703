import Icon from '../../generic/Icon';
import * as style from './Grape.module.less';

interface GrapeProps {
    grapeType: 'green' | 'blue';
    name?: string;
    summary?: string;
}

export default function Grape({ grapeType, name, summary }: GrapeProps) {
    return (
        <>
            <span className={`${style.grapeType} ${style[grapeType]}`}>
                <Icon className="icon-grape" />
                {grapeType == 'blue' ? 'Blå drue' : 'Grønn drue'}
            </span>
            <h2>{name}</h2>
            {summary?.split('\n').map((t, i) => <p key={i}>{t}</p>)}
        </>
    );
}
