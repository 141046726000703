import { PortableTextBlock, toPlainText } from '@portabletext/react';
import cn from 'classnames';
import { useEffect } from 'react';
import slugify from 'slugify';
import useArrayState from '../../utils/useArrayState';

interface TocEntry {
    title: string;
    key: string;
    active?: boolean;
}

const keyIs = (id: string | undefined) => (e: TocEntry) => e.key === id;
const setActive =
    (active: boolean) =>
    ({ key, title }: TocEntry): TocEntry => ({ key, title, active });

export default function TableOfContents({ entries: initial, contentQuerySelector }: { entries: TocEntry[]; contentQuerySelector: string }) {
    const [entries, setEntries] = useArrayState(initial);

    useEffect(() => {
        const elements = new Map<Element, boolean>();

        // eslint-disable-next-line compat/compat
        const io = new IntersectionObserver(
            (entries) => {
                for (const entry of entries) {
                    elements.set(entry.target, entry.isIntersecting);
                }

                let id: string | undefined = undefined;
                let active = false;
                for (const [element, visible] of elements.entries()) {
                    if (element.id) {
                        setEntries.editWhere(keyIs(id), setActive(active));
                        id = element.id;
                        active = visible;
                    } else {
                        active ||= visible;
                    }
                }
                setEntries.editWhere(keyIs(id), setActive(active));
            },
            { rootMargin: '-25% 0px 0px 0px' },
        );

        document.querySelectorAll(contentQuerySelector).forEach((e) => {
            elements.set(e, false);
            io.observe(e);
        });
    }, [contentQuerySelector]);

    if (entries.length < 2) return null;

    return (
        <ul>
            {entries.map(({ title, key, active }) => (
                <li key={key} className={cn({ active })}>
                    <a href={'#' + key}>{title}</a>
                </li>
            ))}
        </ul>
    );
}

export function getToc(content: PortableTextBlock[]): { title: string; key: string }[] {
    return (
        content
            ?.filter((r) => r._type == 'block' && r.style === 'h2')
            .map((r) => toPlainText(r))
            .map((title) => ({ title, key: slugify(title) })) ?? []
    );
}
